export const environment = {
  production: true,
  apiBaseUrl: 'https://mtxapis.angeltrax.com',
  googleMapsApiKey: 'AIzaSyAKPEbmAod-EdEGvwvZyyoVjuvxajED9mE',
  hereMapsApiKey: 'I8I3Dkod8pC0L53-7uP1j38LwTapMbmwWXSjGzGT9Pg',
  politicalBoundaryApiUrl: 'https://nominatim.angeltrax.com',
  defaultMapProvider: 'google',
  telemetryBaseUrl: 'https://otel.angeltrax.com:443/v1',
  oauthConfig: {
    issuer: 'https://accounts.angeltrax.com/realms/master',
    redirectUri: `${window.location.origin}/index.html`,
    clientId: 'mtx-general-webui-prod',
    scope:
      'openid profile email phone offline_access mtx-user-api-v1.access mtx-admin-api-v1.access mtx-avl-api-v1.access mtx-super-admin-api-v1.access mtx-driver-analytics-api-v1.access mtx-transit-api-v1.access mtx-student-tracking-api-v1.access mtx-sav-api-v1.access mtx-video-api-v1.access mtx-health-api-v1.access',
    responseType: 'code',
    showDebugInformation: false,
    clientLoginPath: '/login',
    clientLoginRedirectParamName: 'redirect',
    clientPermissionDeniedPath: '/error-pages/access-denied',
    clockSkewInSec: 300,
    // diablePKCI: false,
  },
};
