import { environment } from '../environments/environment';
import type { Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UserPreferencesService } from './user/user-preferences/shared/user-preferences.service';
import type { OnDestroy, OnInit } from '@angular/core';
import { Component, DestroyRef, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IdleService, TelemetryService } from '@angeltrax/ngx-aui';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'mtx-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  public isLoadingRoute = false;

  public isLoadingApp = true;

  public authSetupFailed = false;

  public authWindowReload?: Observable<number>;

  private readonly title = 'mototrax-webui';

  private readonly isProduction = environment.production;

  private logoutSubscription?: Subscription;

  private readonly loginSubscription?: Subscription;

  private authSub?: Subscription;

  /**
   * Sets google analytics and navigatorIntercepter
   */
  public constructor(@Inject(Router) private readonly router: Router,
    @Inject(TranslateService) private readonly translate: TranslateService,
    @Inject(IdleService) private readonly idleService: IdleService,
    @Inject(UserPreferencesService) private readonly userPreferencesService: UserPreferencesService,
    @Inject(AuthService) private readonly authService: AuthService,
    @Inject(TelemetryService) private readonly telemetryService: TelemetryService,
    @Inject(DestroyRef) private readonly destroy: DestroyRef) {}

  /** Translates page */
  public ngOnInit(): void {
    if (environment.production) {
      this.telemetryService.init();
    }
    this.configureTranslations();

    if (sessionStorage.getItem('debugLogs') === 'true') {
      this.router.events.subscribe({
        // eslint-disable-next-line no-console
        next: evt => console.log(evt),
      });
    }

    this.idleService.idle$.pipe(takeUntilDestroyed(this.destroy))
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      .subscribe(async(isIdle) => {
        if (isIdle) {
          if (await this.authService.isLoggedIn()) {
            const path = this.router.url;
            await this.authService.logout(path);
          }
        }
      });
  }

  /**
   * Destroys subs on exit
   */
  public ngOnDestroy(): void {
    if (this.logoutSubscription) {
      this.logoutSubscription.unsubscribe();
      this.logoutSubscription = undefined;
    }

    if (this.authSub) {
      this.authSub.unsubscribe();
      this.authSub = undefined;
    }
  }

  private configureTranslations(): void {
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }

}
